import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import { getChannelListOptions } from './channelListOptions';

const apiKey = process.env.REACT_APP_STREAM_KEY;
const urlParams = new URLSearchParams(window.location.search);

// save user to local storage
const user = urlParams.get('user') || localStorage.getItem('user');
localStorage.setItem('user', user!);

const targetOrigin = urlParams.get('target_origin') || process.env.REACT_APP_TARGET_ORIGIN;
const api_endpoint = process.env.REACT_APP_API_ENDPOINT

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);
async function launch() {


  // grab api token from https://api.satori.earth/api/token/${user}
  const response = await fetch(`${api_endpoint}/token/${user}`);
  const data = await response.json();
  const userToken = data.token;


  const userToConnect: { id: string; name?: string; image?: string } = {
    id: user!,
    name: data.name,
    image: `https://ui-avatars.com/api/?name=${data.name}&background=random&color=fff&size=128`,
  };

  const channelListOptions = getChannelListOptions( user);

  root.render(
  <React.StrictMode>
    <App
      apiKey={apiKey!}
      userToConnect={userToConnect}
      userToken={userToken}
      targetOrigin={targetOrigin!}
      channelListOptions={channelListOptions}
    />
  </React.StrictMode>,
);
}

launch();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
