// scaffold component
import React, { useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import type { StreamChatGenerics } from '../../types';

export const InitChannelCreation = () => {
  const { client, setActiveChannel, channel } = useChatContext<StreamChatGenerics>();
  // take user and partner from URL params
  const partner = new URLSearchParams(window.location.search).get('partner');
  const user = new URLSearchParams(window.location.search).get('user');

  useEffect(() => {
    async function createChannel() {
      if (partner && user) {
        console.log('partner and user', partner, user)
        const conversation = client.channel('messaging', {
          members: [client.userID!, partner!],
        });
        await conversation.watch()
        console.log('conversation', conversation)
        setTimeout(() => {

          setActiveChannel(conversation).then(console.log);
        }, 400)
        // clear URL params
        // window.history.replaceState({}, document.title, "/");
      }

    }
    createChannel()

  }, [partner, user])
  return <div></div>;
};