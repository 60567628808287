import React, { useState } from 'react';
import type { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import { Channel, Chat } from 'stream-chat-react';

import 'stream-chat-react/dist/css/v2/index.css';
import './styles/index.css';

import {
  ChannelInner,
  MessagingSidebar,
  MessagingThreadHeader,
  SendButton
} from './components';

import { GiphyContextProvider } from './context';
import {
  useConnectUser,
  useChecklist,
  useMobileView,
  useTheme,
  useUpdateAppHeightOnResize
} from './hooks';

import type { StreamChatGenerics } from './types';
import { CreateChannel } from './components/CreateChannel/CreateChannel';
import { InitChannelCreation } from './components/CreateChannel/InitChannelCreation';

type AppProps = {
  apiKey: string;
  userToConnect: { id: string; name?: string; image?: string };
  userToken: string | undefined;
  targetOrigin: string;
  channelListOptions: {
    options: ChannelOptions;
    filters: ChannelFilters;
    sort: ChannelSort;
  };
};

const App = (props: AppProps) => {
  console.log('props', props)
  const { apiKey, userToConnect, userToken, targetOrigin, channelListOptions } = props;
  const [isCreating, setIsCreating] = useState(false);

  const chatClient = useConnectUser<StreamChatGenerics>(apiKey, userToConnect, userToken);
  // @ts-expect-error
  window.chatClient = chatClient;
  const toggleMobile = useMobileView();
  const theme = useTheme(targetOrigin);
  useChecklist(chatClient, targetOrigin);
  useUpdateAppHeightOnResize();

  if (!chatClient) {
    return null; // render nothing until connection to the backend is established
  }

  return (
    <Chat client={chatClient} theme={`messaging ${theme}`}>
          <InitChannelCreation></InitChannelCreation>

      <MessagingSidebar
        channelListOptions={channelListOptions}
        onClick={toggleMobile}
        onPreviewSelect={() => setIsCreating(false)}
        theme={theme}
      />
      <Channel
        maxNumberOfFiles={10}
        multipleUploads={true}
        SendButton={SendButton}
        ThreadHeader={MessagingThreadHeader}
        TypingIndicator={() => null}
      >
        <GiphyContextProvider>
          <ChannelInner theme={theme} toggleMobile={toggleMobile} />
        </GiphyContextProvider>
      </Channel>
    </Chat>
  );
};

export default App;
