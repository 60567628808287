import type { ChannelMemberResponse } from 'stream-chat';

import type { StreamChatGenerics } from '../types';

export { ChannelInfoIcon } from './ChannelInfoIcon';
export { ChannelSaveIcon } from './ChannelSaveIcon';
export { CloseThreadIcon } from './CloseThreadIcon';
export { CommandIcon } from './CommandIcon';
export { CreateChannelIcon } from './CreateChannelIcon';
export { EmojiIcon } from './EmojiIcon';
export { HamburgerIcon } from './HamburgerIcon';
export { LightningBoltSmall } from './LightningBoltSmall';
export { SendIcon } from './SendIcon';
export { XButton } from './XButton';
export { XButtonBackground } from './XButtonBackground';

export const getCleanImage = (member: ChannelMemberResponse<StreamChatGenerics>) => {
  let image = member.user?.image || '';
  console.log("image", image);
  if (!image) {
    image = "https://ui-avatars.com/api/?name=" + member.user?.name + "&background=0D8ABC&color=fff"
  }
  return image;
};

